.swal2-title {
    font-size: 1rem !important;
}
.swal2-content {
    font-size: 0.9rem !important;
}
.swal2-styled.swal2-confirm {
    font-size: 0.9rem !important;
    background-color: #fd591d !important;
}
.swal2-styled.swal2-confirm:focus {
    outline: 0;
    box-shadow: 0 0 0 3px #ffab8d !important;
}
.swal2-styled.swal2-deny {
    background-color: rgba(239, 79, 79, 0.55) !important;
    font-size: 0.9rem !important;
}
.swal2-styled.swal2-deny:focus {
    outline: 0;
    background-color: rgba(197, 56, 56, 0.6) !important;
}
.swal2-styled.swal2-cancel {
    font-size: 0.9rem !important;
    background: #d5d5d5 !important;
}
.swal2-styled.swal2-cancel:focus {
    outline: 0;
    background-color: #b0b0b0 !important;
}
.swal2-container {
    z-index: 999999 !important;
}
