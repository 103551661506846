.card-kuis .title-card {
    color: #33313b !important;
}
.card-kuis .subtitle-card {
    font-weight: 600;
    font-size: 16px;
}
.card-kuis .text {
    color: #bbbfca;
    font-weight: 500;
}
.card-kuis a.button {
    margin-bottom: 10px;
    box-shadow: none !important;
    background-color: rgba(106, 9, 125, 0.15) !important;
    color: #6a097d !important;
}
.card-kuis a.button .text-button {
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}
.bordered {
    background: #dddddd;
    height: 1px;
}
.card-soal .soal {
    font-weight: 400;
    font-size: 16px;
}
.card-invoice .metode-pembayaran {
    width: 50px;
}

.card-ranking-tertinggi {
    background-color: #dad6ff !important;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: max-content !important;
    position: relative;
}

.card-ranking-tertinggi:before {
    width: 67px;
    height: 67px;
    background: rgba(116, 103, 239, 0.27);
    content: ' ';
    border-radius: 100%;
    position: absolute;
    bottom: -2px;
    left: -12px;
}

.kejakan-selesai {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-ranking-tertinggi.card-paket-profile {
    width: 100% !important;
}

/* CARD BERANDA */
.card-beranda {
    background-color: #dddddd;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    position: relative;
}
.card-beranda:hover {
    text-decoration: underline;
    box-shadow: 5px 4px 15px rgba(0, 0, 0, 0.289);
    cursor: pointer;
}

/* CARD BUNDLE */
.card-bundle {
    background-color: #dddddd;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    position: relative;
}
.card-bundle.secondary {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
    border: none;
    color: #000000 !important;
}

/* PRIMARY */
.card-beranda.primary {
    background-color: #843cf633;
    border: 1px solid rgba(116, 103, 239, 1);
    color: rgba(116, 103, 239, 1) !important;
}
.card-beranda.primary:before {
    background: rgba(116, 103, 239, 0.7);
}
/* DANGER */
.card-beranda.danger {
    background-color: #f8f7de;
    border: 1px solid #fecd1a;
    color: #fecd1a !important;
}
.card-beranda.danger:before {
    background: #fecd1a;
}
/* WARNING */
.card-beranda.warning {
    background-color: #ff763b36;
    border: 1px solid #ff8450;
    color: #ff8450 !important;
}
.card-beranda.warning:before {
    background: #ff8450;
}
/* INFO */
.card-beranda.info {
    background-color: #6a8eff66;
    border: 1px solid #6a8eff;
    color: #6a8eff !important;
}
.card-beranda.info:before {
    background: #6a8eff;
}
/* SUCCESS */
.card-beranda.success {
    background-color: #38dfb44f;
    border: 1px solid #16c79a;
    color: #16c79a !important;
}
.card-beranda.success:before {
    background: #2ddaaec9;
}

/* SECONDARY */
.card-beranda.secondary {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
    border: none;
    color: #000000 !important;
}
.card-beranda.secondary:before {
    background: #222222;
}

/* card materi */
div.card-materi {
    width: 100%;
    max-width: 220px;
    margin: auto;
    height: 285px;
    position: relative;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1) !important;
    background-color: #dad6ff;
}

.card-materi .hiasan {
    position: absolute;
    z-index: 0;
}

.card-materi .hiasan .item-1 {
    width: 60px;
    height: 60px;
    background: rgb(255, 255, 255, 0.1);
}

.card-materi .hiasan .item-2 {
    width: 60px;
    height: 60px;
    background: rgb(255, 255, 255, 0.25);
}

.card-materi .hiasan .item-3 {
    width: 60px;
    height: 60px;
    background: rgb(255, 255, 255, 0.5);
}

.card-materi .hiasan .item-4 {
    width: 60px;
    height: 60px;
    background: rgb(255 255 255);
}

.card-materi h4 {
    position: absolute;
    left: 10px;
    bottom: 10px;
    margin: 0;
    color: #ffffff;
    max-width: 125px;
}
.no-border {
    border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
}
@media screen and (max-width: 600px) {
    .dashinfo {
        padding: 30px 20px 30px 20px !important;
    }

    .no-flex {
        display: block !important;
    }

    .dash-welcome {
        flex-direction: column;
    }
}

/* Card profile */
.thumbnail-profile {
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Benefit */
.img-status-benefit {
    width: 20px !important;
    height: 20px !important;
}

/* Badge benefit */
.badge-status-benefit {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.badge-status-benefit.bg-green {
    background-color: #37c13c !important;
}

/* Card Referral */

.card-referral {
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    position: relative;
}


.card-komisi-belum {
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    position: relative;
}
.card-komisi-belum:before {
    width: 67px;
    height: 67px;
    background: #38383821;
    content: ' ';
    border-radius: 100%;
    position: absolute;
    bottom: -2px;
    left: -12px;
}

.card-referral:before {
    width: 67px;
    height: 67px;
    background: #38383821;
    content: ' ';
    border-radius: 100%;
    position: absolute;
    bottom: -2px;
    left: -12px;
}

.cursor-pointer {
    cursor: pointer;
}
