@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

@font-face {
  font-family: "Inter";
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"),
    url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("https://fonts.googleapis.com/css2?family=Inter&display=swap"),
    url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//     font-family: 'Proxima Nova Rg';
//     src: url('styles/fonts/ProximaNova-Bold.woff2') format('woff2'),
//         url('styles/fonts/ProximaNova-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Proxima Nova Rg';
//     src: url('styles/fonts/ProximaNova-Regular.woff2') format('woff2'),
//         url('styles/fonts/ProximaNova-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }



.table-cell {
  width: 150px;
  //   width: 100%;
  height: 50px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  border: 1px;
}

.table-cell-row {
  width: 75px;
  //   width: 100%;
  height: 50px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  border: 1px;
}
.table-res {
  min-width: 1050px;
  overflow: scroll;

  @for $width from 1 through 1000 {

    // You can customize the range as needed
    .table-cell-#{$width}px {
      width: #{$width}px;
      //   height: 50px;
      //   border-radius: 5px;
      //   border: 1px solid black; // Add a color here
    }
  }
}
.t-header {
  width: 70px;
  height: 44px;
  padding: 10px;
  gap: 10px;
  background-color: #f4f7fc;
  text-transform: uppercase;
}
