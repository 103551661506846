@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,300&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
}
@media print {
    body,
    *,
    html {
        visibility: hidden;
    }
    .ps {
        overflow: scroll !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
    }
}
/* img {
    width: 100%;
} */

.brand-area {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.brand-area .brand {
    width: 91.84px;
    height: 40px;
}
.brand-area .brand.img-no-text {
    width: 28px;
    height: 28px;
}
.brand-mobile {
    width: 100px !important;
    height: 43.55px !important;
}

.viewer-document {
    align-content: right;
    overflow: hidden;
    margin: auto;
}

.label-sidenav {
    font-family: 'Poppins', sans-serif;
}

button,
a {
    font-family: 'Poppins', sans-serif !important;
}

.icon-bimble-platform {
    width: 30px !important;
    height: 30px !important;
}

/* SESSION */
.bg-session {
    background-image: url('/assets/images/illustrations/bg.png');
    background-attachment: fixed;
    background-repeat: repeat-x;
    background-size: contain;
    background-position: bottom;
}

.card-session {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
}
.btn-radius-10 {
    border-radius: 22px !important;
    width: 120px;
}

.MuiTypography-body1 {
    font-family: 'Poppins', sans-serif !important;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.bg-blue {
    background-color: #0779e4;
}

/* card item nomor soal */
.item-card-nomor {
    width: 100%;
    max-width: 34px;
    width: 34px;
    height: 34px;
    margin: 4px;
}
