.MuiInputBase-input {
    font-family: 'Poppins', sans-serif !important;
}

.form-search {
    box-shadow: 0px 0px 2px #43434359;
    margin-bottom: 15px;
    border-radius: 5px;
    max-height: 45px;
}

.input-search {
    min-height: 25px;
    border: 0px;
    background-color: transparent;
    width: 100%;
    outline: 0;
}
