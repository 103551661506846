.MuiTableCell-root {
    word-break: normal !important;
    font-family: 'Poppins', sans-serif !important;
}

.img-medal {
    width: 25px !important;
}

.valign-baseline {
    vertical-align: baseline;
}

.MuiTable-root.bimble-table {
    min-width: 800px;
}
