.badge-custom {
	font-size: 12px;
	line-height: 1;
	padding: 0.375rem 0.5625rem;
	display: inline-block;
}
.badge-custom.error {
	color: #fff;
	background-color: #ff5e5e;
}
.badge-custom.primary {
	color: #fff;
	background-color: #16c79a;
}
.badge-custom.warning {
	color: #fff;
	background-color: #EBB666 !important;
	border-radius: 20px!important;
}
.badge-custom h6 {
	text-transform: capitalize;
}
.badge-custom-warning {
	font-size: 12px!important;
	line-height: 1!important;
	padding: 0.200rem 0.5625rem!important;
	display: inline-block!important;
}

.w-full {
	width: -webkit-fill-available!important;
}

  .ribbon {
	height: 188px!important;
	position: relative;
	color: white!important;
  }
  .ribbon1 {
	position: absolute;
	top: 8px;
    right: 20px;
  }
  .ribbon1:after {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-left: 50px solid transparent;
    border-right: 39px solid transparent;
    border-top: 15px solid #EBB666;
  }
  .ribbon1 span {
	position: relative;
	display: block;
	text-align: center;
	background: #EBB666;
	font-size: 14px;
	line-height: 2;
	padding: 12px 8px 10px;
	border-top-right-radius: 8px;
	width: 90px;
	font-weight: bold;
  }
  .ribbon1 span:before, .ribbon1 span:after {
	position: absolute;
	content: "";
  }
  .ribbon1 span:before {
   height: 6px;
   width: 6px;
   left: -6px;
   top: 0;
   background: #EBB666;
  }
  .ribbon1 span:after {
   height: 6px;
   width: 8px;
   left: -8px;
   top: 0;
   border-radius: 8px 8px 0 0;
   background: #EBB666;
  }
  @media (min-width: 500px) {
	.ribbons-wrapper {
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;
	}
	.ribbon {
	  width: 48%;
	}
  }